<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Edit Buy A Restaurant Business</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputtitle" class="col-sm-2 col-form-label">Title <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.title" @blur="validationRule()"
										class="form-control" id="inputtitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputSubTitle" class="col-sm-2 col-form-label">Sub Title <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="dataRequest.subTitle" @blur="validationRule()"
										class="form-control" id="inputSubTitle">
									<small class="text-danger">{{validationData.subTitle}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDescription" class="col-sm-2 col-form-label">Description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
                                    <textarea class="form-control" id="inputDescription" v-model="dataRequest.description" @blur="validationRule()"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<img v-if="currentImage1" :src="env.mediaUrl+currentImage1" class="banner-image mt-2"/>
								</div>
							</div>
							
							<div class="form-group row">
								<label for="inputImage1" class="col-sm-2 col-form-label"> Image1 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage1" @change="readImage1">
									<img v-if="dataRequest.image1" v-bind:src="image1" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image1}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<img v-if="currentImage2" :src="env.mediaUrl+currentImage2" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage2" class="col-sm-2 col-form-label"> Image2 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage2" @change="readImage2">
									<img v-if="dataRequest.image2" v-bind:src="image2" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image2}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<img v-if="currentImage3" :src="env.mediaUrl+currentImage3" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage3" class="col-sm-2 col-form-label"> Image3 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage3" @change="readImage3">
									<img v-if="dataRequest.image3" v-bind:src="image3" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image3}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"></label>
								<div class="col-sm-10">
									<img v-if="currentImage4" :src="env.mediaUrl+currentImage4" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage4" class="col-sm-2 col-form-label"> Image4 <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage4" @change="readImage4">
									<img v-if="dataRequest.image4" v-bind:src="image4" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image4}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'clientEdit',
		metaInfo: {
            title: "client Edit",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage1: "",
				currentImage2: "",
				currentImage3: "",
				currentImage4: "",
				image1: "",
				image2: "",
				image3: "",
				image4: "",
				filter: {
					page: "",
                    orderBy: "",
                    sortKey: "",
                    name: "",
				},
				dataRequest: {
					title: "",
					subTitle: "",
					description: "",
					image1: "",
					image2: "",
					image3: "",
					image4: ""
				},
				validationData: {
					title: "",
					subTitle: "",
					description: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				buyHospitalityUpdateAction: 'buyHospitalityUpdateAction',
				buyHospitalityDetailAction: 'buyHospitalityDetailAction'
			}),
			readImage1(event) {
				this.dataRequest.image1 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image1 = e.target.result
					this.validationRule()
				};
			},
			readImage2(event) {
				this.dataRequest.image2 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image2 = e.target.result
					this.validationRule()
				};
			},
			readImage3(event) {
				this.dataRequest.image3 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image3 = e.target.result
					this.validationRule()
				};
			},
			readImage4(event) {
				this.dataRequest.image4 = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image4 = e.target.result
					this.validationRule()
				};
			},
			async getFields(){
				let option = {
					type: "update",
					id: this.dataRequest.id
				}
				await this.buyHospitalityDetailAction({
					...option
				}).then(res => {
					this.currentImage1 = res.data.detail.image1
					this.currentImage2 = res.data.detail.image2
					this.currentImage3 = res.data.detail.image3
					this.currentImage4 = res.data.detail.image4
					this.dataRequest.title = res.data.detail.title
					this.dataRequest.subTitle = res.data.detail.sub_title
					this.dataRequest.description = res.data.detail.description
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('id', this.dataRequest.id);
				formData.append('title', this.dataRequest.title); 
				formData.append('subTitle', this.dataRequest.subTitle); 
				formData.append('description', this.dataRequest.description); 
				formData.append('image1', this.dataRequest.image1); 
				formData.append('image2', this.dataRequest.image2); 
				formData.append('image3', this.dataRequest.image3); 
				formData.append('image4', this.dataRequest.image4); 

				await this.buyHospitalityUpdateAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'buy-hospitality-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.dataRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.dataRequest.subTitle == "") {
					this.validationData.subTitle = "The sub title field is required."
					isValidation.push(true)
				} else {
					this.validationData.subTitle = ""
					isValidation.push(false)
				}
				if (this.dataRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
			this.dataRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>